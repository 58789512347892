@font-face {
    font-family: "Open Sans";
    src: local("OpenSans-Regular"), url("./fonts/OpenSans-Regular.woff") format("woff");
}

h1, h2, h3, h4, h5 {
    font-family: 'Open Sans', sans-serif;
}

.App-logo {
    width: 30vmin;
    pointer-events: none;
}


body {
    font-size: 0.8rem;
    margin-bottom: 40px;
}

.form-control {
    font-size: 0.8rem;
}

.form-select {
    font-size: 0.8rem;
}

.btn {
    font-size: 0.8rem;
}

.dropdown-menu {
    font-size: 0.8rem;
}

.btn:disabled {
    background-color: #337ab7;
    border-color: #2e6da4;
}

.form-check-input {
    font-size: 1.2rem;
    margin-top: 0.5rem;
}

.form-radio-input .form-check-input {
    font-size: 1rem;
    margin-top: 0.1rem;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #4189C7;
    border-color: #4189C7;
}

.btn-check:checked + .btn-outline-primary {
    color: #fff;
    background-color: #4189C7;
    border-color: #4189C7;
}

.pagination button {
    border: 0;
    background-color: #fff;
}

.footer {
    box-shadow: 0 -0.25rem .75rem rgba(0, 0, 0, .05);
    background-color: white;
    font-size: 10px;
    color: black;
    text-align: center;
    padding: 0.4rem 1rem;
    width: 100%;
    /* Sticky footer */
    height: 0px;
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
}

.link {
    color: #1a6db3;
    cursor: pointer;
    text-decoration: none;
}


/*.quality-value-column {
    width: 140px;
}
.quality-lab-result-column {
    width: 100px;
}*/

.react-draggable .modal-header, .cursor {
    cursor: move;
}

.table tr.deleted td {
    color: #aaaaaa;
}

    .table tr.deleted td .nav-link {
        color: #aaaaaa;
    }

.filter-select {
    font-size: 0.8rem;
    width: auto;
    margin-left: 0.5rem;
}

.config-select {
    font-size: 0.8rem;
}

.wide-dialog .modal-dialog {
    max-width: 600px;
}

.detail-dialog .modal-dialog {
    max-width: 1100px;
}

.tall-dialog .modal-body {
    max-height: 80vh;
    overflow-y: auto;
}

.selected {
    font-weight: bold;
}

.default-value {
    font-weight: bold;
    color: #878787;
}

.default-value.lab {
    font-weight: bold;
    color: #2596f5;
}

.table tr.offspec {
    background-color: #ff9999;
}

.table td.offspec {
    background-color: #ff9999;
}

.modal-90w {
    width: 80%;
    max-width: 100%;
}
/* Styled bootstrap tabs */
.tabs {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}

    .tabs .nav-item button {
        border-bottom: 0px;
        color: #000000;
    }

    .tabs .nav-item a {
        border-bottom: 0px;
        color: #000000;
    }

    .tabs .nav-link {
        border: 0;
    }

        .tabs .nav-link.active {
            border-color: white;
            border-bottom: 2px solid #4189C7;
        }


/* Accordion header */
.accordion-button {
    height: 2.5rem;
    color: #000;
}

    .accordion-button:not(.collapsed) {
        color: #000;
    }

.warning.accordion-header > .accordion-button {
    background: #ffce54;
}

    .warning.accordion-header > .accordion-button.collapsed {
        background: #ffce54;
    }

.loading-spinner-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.tr-subrow {
    background-color: #EBF1FE;
}


.tr-odd {
    background-color: rgba(0, 0, 0, 0.05);
}

.tr-even {
    background-color: white;
}

.fade-content {
    opacity: 0.5;
}

.overlay-content {
    position: absolute;
    top: 25%;
    width: 100%;
    text-align: center;
}

.hashlink-text {
    color: white;
    text-decoration: none;
    font-size: 1em;
    font-weight: normal;
}

a.hashlink-text:hover {
    color: white;
    text-decoration: none;
}

.bg-secondary.lighter {
    background-color: #999999 !important;
}

.clickable {
    cursor: pointer;
}