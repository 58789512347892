.react-datepicker__time-container {
    width: 100px;
}

.react-datepicker__time-container 
.react-datepicker__time 
.react-datepicker__time-box {
    width: 100px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 100px;
}
