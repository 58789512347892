.notification-alert {
    width: 400px;
    position: fixed;
    top: 10px;
    right: 10px;
    margin-left: 5px;
    padding-left: 2rem;
    z-index: 99999;
}

.toast-header-background {
    background-color: transparent;
}
 
.toast-body-background {
    background-color: white;
    opacity: 0.85;
}