.lot-split-bar {
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 2rem;
    width: 100%;
    overflow: hidden;
}

.bar-data {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lot-split-text {
    color: white;
    font: 200 1rem sans-serif;
    text-align: center;
}

.lot-split-details table {
    border-collapse: collapse;
    width: 100%;
}

.lot-split-details td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 5px;
}